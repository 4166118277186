import React from "react";

const FileViewer = ({ fileUrl, fileType }) => {
  // Function to determine the correct display method based on file type
  const renderFile = () => {
    if (fileType === "pdf") {
      return (
        <iframe
          src={fileUrl}
          title="PDF Viewer"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
          }}
        />
      );
    } else if (fileType === "doc" || fileType === "docx") {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            fileUrl
          )}`}
          title="DOC Viewer"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      );
    } else if (fileType === "image") {
      return (
        <img
          src={fileUrl}
          alt="Viewer"
          style={{
            maxWidth: "100%",
            height: "auto",
            display: "block",
            border: "none",
          }}
        />
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        padding: "10px",
        borderRadius: "8px",
        background: "#f9f9f9",
      }}
    >
      {renderFile()}
    </div>
  );
};

export default FileViewer;
