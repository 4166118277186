import React, { useState } from "react";
import {
  Link,
  Box,
  Flex,
} from "@chakra-ui/react";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { ArrowBackIcon } from "@chakra-ui/icons";
import FileViewer from "./FileViewer";
import GeneralLayout from "../../layout/GeneralLayout";

export default function CarePolicy() {
  // State to track the selected policy
  const [selectedPolicy, setSelectedPolicy] = useState("Complaints_Policy");

  // Function to render the selected policy in FileViewer
  const renderFile = () => {
    switch (selectedPolicy) {
      case "Complaints_Policy":
        return <FileViewer fileUrl="/policies/Complaints_Policy.pdf" fileType="pdf" />;
      case "Safeguarding_Children":
        return <FileViewer fileUrl="/policies/SafeguardingChildren.pdf" fileType="pdf" />;
      case "Adult_Safeguarding":
        return <FileViewer fileUrl="/policies/Adult_Safeguarding_Policy.pdf" fileType="pdf" />;
      default:
        return <p>Select a policy to view.</p>;
    }
  };

  return (
    <GeneralLayout
      page_type="care"
      pageContent={
        <>
          <div w={"100%"} className="landing-bg">
            
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="Our Policies"
              breadcrumb={
                <>
                  <Link href="/healthcare">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />
            <div className="flex w-full p-5 md:w-[75%] m-auto">
              <aside className="flex-[1] ">
                <h1 className="text-md font-bold py-5 px-5 bg-blue-400 mb-2 text-white">Policies</h1>
                <div className="flex flex-col gap-4">
                  <Link
                    className="px-5 py-2 bg-slate-200 hover:bg-blue-400 hover:text-white"
                    onClick={() => setSelectedPolicy("Complaints_Policy")}
                  >
                    Complaints Policy
                  </Link>
                  <Link
                    className="px-5 py-2 bg-slate-200 hover:bg-blue-400 hover:text-white"
                    onClick={() => setSelectedPolicy("Safeguarding_Children")}
                  >
                    Safeguarding Children Policy
                  </Link>
                  <Link
                    className="px-5 py-2 bg-slate-200 hover:bg-blue-400 hover:text-white"
                    onClick={() => setSelectedPolicy("Adult_Safeguarding")}
                  >
                    Adult Safeguarding Policy
                  </Link>
                </div>
              </aside>
              <aside className="flex-[4]">
                {renderFile()}
              </aside>
            </div>
          </div>
        </>
      }
    />
  );
}
