import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { Box } from "@chakra-ui/react";
import { USER_LEVELS } from "../util/data";
import Home from "../Home";
import Welcome from "../application/Welcome";
import LitmusHealthcare from "../pages/care/Healthcare";
import Career from "../career/Career";
import JobDetails from "../career/ViewVacancy";
import Register from "../auth/Resgister";
import Login from "../auth/Login";
import ForgotPassword from "../auth/ForgotPassword";
import Verify from "../auth/Verify";
import Test from "../application/UploadTest";
import Contact from "../pages/ContactUs";
import ResetPassword from "../auth/ResetPassword";
import Error404 from "../pages/Error404";
import PersonalInfo from "../application/PersonalInfo";
import WorkHistory from "../application/WorkHistory";
import Certificate from "../application/Certificates";
import UploadResume from "../application/UploadResume";
import Address from "../application/Address";
import UploadPOI from "../application/UploadPOI";
import Nok from "../application/Nok";
import MobilityStatus from "../application/MobilityStatus";
import RTWUpload from "../application/RTWUpload";
import InterviewScheduler from "../Interview/InterviewScheduler";
import InterviewFeedbackForm from "../Interview/InterviewFeedbackForm";

import Reference from "../onboarding/Reference";
import Dashboard from "../dashboard/Dashboard";
import Complaince from "../dashboard/compliance/Compliance";
import Timesheet from "../dashboard/TimeSheet";

import Profile from "../dashboard/profile/Profile";
import { Referee } from "../onboarding/Referee";
import DBS from "../application/DBS";
import LiveCare from "../pages/care/LiveInCare";
import Domiciliary from "../pages/care/Domiciliary";

import useUserContext, { AuthProvider } from "../components/UserContext";

import ProofOfWork from "../onboarding/ProofOfWork";
import Provisonal from "../onboarding/Provisional";
import ContractAgreement from "../agreement/ContractAgreement";
import FeedbackForm from "../pages/FeedBack";
import AdminContractAgreement from "../agreement/AdminCopy";
import Uniform from "../finalstep/Uniform";

import BankDetails from "../finalstep/BankDetails";
import NightWorker from "../finalstep/NightWorker";
import OptoutAgreement from "../finalstep/OptoutAgreement";
import CheckList from "../finalstep/StarterCheckList";
import AdminOptOutAgreement from "../finalstep/AdminOptOut";
import AboutCare from "../pages/care/About";
import Pricing from "../pages/care/Pricing";
import Clientele from "../pages/recruitment/OurClintele";
import UploadPassportPhoto from "../finalstep/UploadPassportPhoto";
import LitmusRecruitment from "../pages/recruitment/Recruitment";
import CareContact from "../pages/care/CareContact";
import CustomRedirect from "./Redirect";
import SatisfactionSurvey from "../survey/SatisfactionSurvey";
import ProfileSummary from "../summary/ProfileSummarry";
import CarePolicy from "../pages/care/CarePolicy";

export const AppRoutes = () => {
  const initialUrl = window.location.href;

  // // const isLoggedIn = Cookies.get("isLoggedin");
  const userLevel = Cookies.get("userLevel");

  const { loading, userDetails } = useUserContext();

  // const userLevel = useAccessLevel();
  if (loading) {
    return (
      <Box
        py={"17em"}
        alignItems={"center"}
        justifyItems={"center"}
        height={"100vh"}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/feedback" element={<FeedbackForm />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/healthcare" element={<LitmusHealthcare />} />
        <Route path="/care" element={<LitmusHealthcare />} />
        <Route path="/care/domiciliary" element={<Domiciliary />} />
        <Route path="/care/live-in-care" element={<LiveCare />} />
        <Route path="/care/career" element={<Career />} />
        <Route path="/care/career/:jobToken" element={<JobDetails />} />
        <Route path="/care/pricing" element={<Pricing />} />
        <Route path="/care/pricing" element={<Pricing />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/care/about" element={<AboutCare />} />
        <Route path="/career" element={<Career />} />
        <Route path="/carecoordinator" element={<CustomRedirect />} />
        <Route path="/commcareworker" element={<CustomRedirect />} />
        <Route path="/satisfactory-survey" element={<SatisfactionSurvey />} />
        <Route path="/care/policies" element={<CarePolicy />} />


        <Route path="/recruitment" element={<LitmusRecruitment />} />
        <Route path="/recruitment/career" element={<Career />} />
        <Route path="/career/:jobToken" element={<JobDetails />} />
        <Route path="/recruitment/career/:jobToken" element={<JobDetails />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/test" element={<Test />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/care/contact" element={<CareContact />} />
        <Route path="/recruitment/contact" element={<CareContact />} />
        <Route path="/about" element={<AboutCare />} />
        <Route path="/recruitment/clientele" element={<Clientele />} />
        Clientele
        <Route
          path="/reset-password/:user_id/:reset_token"
          element={<ResetPassword />}
        />
        <Route
          path="/download-agreement/:user_id"
          element={<AdminContractAgreement />}
        />
        <Route
          path="/profile-summary/:user_id"
          element={<ProfileSummary />}
        />
        <Route
          path="/download-agreement/opt-out/:user_id"
          element={<AdminOptOutAgreement />}
        />
        <Route path="/reference/:token" element={<Reference />} />
        {/* Wrap AuthProvider around specific routes */}
        <Route element={<AuthProviderWrapper userLevel={userLevel} />}>
          {/* Application Process Routes */}
          {userLevel >= USER_LEVELS.APPLICATION_PROCESS && (
            <>
              <Route
                path="/application/personal-info"
                element={<PersonalInfo />}
              />
              <Route
                path="/application/work-history"
                element={<WorkHistory />}
              />
              <Route
                path="/application/training/certificate"
                element={<Certificate />}
              />
              <Route path="/application/resume" element={<UploadResume />} />
              <Route path="/application/address" element={<Address />} />
              <Route path="/application/id-upload" element={<UploadPOI />} />
              <Route path="/application/next-of-kin" element={<Nok />} />
              <Route
                path="/application/mobility-status"
                element={<MobilityStatus />}
              />
              <Route
                path="/application/right-to-work"
                element={<RTWUpload />}
              />
            </>
          )}

          {/* Interview Routes */}
          {userLevel >= USER_LEVELS.INTERVIEW && (
            <>
              <Route
                path="/interview/schedule"
                element={<InterviewScheduler />}
              />
              <Route
                path="/interview/feedback/:applicant_token"
                element={<InterviewFeedbackForm />}
              />
            </>
          )}

          {/* Pre-Employment Check Routes */}
          {userLevel >= USER_LEVELS.PRE_EMPLOYMENT_CHECK && (
            <>
              <Route
                path="/employment-check/provisional-offer"
                element={<Provisonal />}
              />
              <Route
                path="/employment-check/rtw-proof"
                element={<ProofOfWork />}
              />
              <Route path="/employment-check/dbs" element={<DBS />} />

              <Route path="/employment-check/referee" element={<Referee />} />
            </>
          )}

          {/* Contract Agreement Routes */}
          {userLevel >= USER_LEVELS.CONTRACT_AGREEMENT && (
            <>
              <Route path="/onboarding/checklist" element={<CheckList />} />
              <Route
                path="/onboarding/upload-passport"
                element={<UploadPassportPhoto />}
              />
              <Route path="/onboarding/uniform" element={<Uniform />} />
              <Route path="/onboarding/bank" element={<BankDetails />} />
              <Route path="/onboarding/nightwork" element={<NightWorker />} />
              <Route
                path="/onboarding/optout-agreement"
                element={<OptoutAgreement />}
              />

              <Route
                path="/contract-agreement"
                element={<ContractAgreement />}
              />
            </>
          )}

          {/* Staff Routes */}
          {userLevel >= USER_LEVELS.STAFF && (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/compliance" element={<Complaince />} />
              <Route path="/dashboard/timesheet" element={<Timesheet />} />
              <Route path="/dashboard/profile/edit" element={<Profile />} />
            </>
          )}
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

const AuthProviderWrapper = ({ userLevel }) => (
  <AuthProvider>
    <Routes>
      {/* Application Process Routes */}
      {userLevel >= USER_LEVELS.APPLICATION_PROCESS && (
        <>
          <Route path="/application/personal-info" element={<PersonalInfo />} />
          <Route path="/application/work-history" element={<WorkHistory />} />
          <Route
            path="/application/training/certificate"
            element={<Certificate />}
          />
          <Route path="/application/resume" element={<UploadResume />} />
          <Route path="/application/address" element={<Address />} />
          <Route path="/application/id-upload" element={<UploadPOI />} />
          <Route path="/application/next-of-kin" element={<Nok />} />
          <Route
            path="/application/mobility-status"
            element={<MobilityStatus />}
          />
          <Route path="/application/right-to-work" element={<RTWUpload />} />
        </>
      )}

      {/* Interview Routes */}
      {userLevel >= USER_LEVELS.INTERVIEW && (
        <>
          <Route path="/interview/schedule" element={<InterviewScheduler />} />
          <Route
            path="/interview/feedback/:applicant_token"
            element={<InterviewFeedbackForm />}
          />
        </>
      )}

      {/* Pre-Employment Check Routes */}
      {userLevel >= USER_LEVELS.PRE_EMPLOYMENT_CHECK && (
        <>
          <Route
            path="/employment-check/provisional-offer"
            element={<Provisonal />}
          />
          <Route path="/employment-check/rtw-proof" element={<ProofOfWork />} />
          <Route path="/employment-check/dbs" element={<DBS />} />

          <Route path="/employment-check/referee" element={<Referee />} />
        </>
      )}

      {/* Contract Agreement Routes */}
      {userLevel >= USER_LEVELS.CONTRACT_AGREEMENT && (
        <>
          <Route path="/onboarding/checklist" element={<CheckList />} />
          <Route
            path="/onboarding/upload-passport"
            element={<UploadPassportPhoto />}
          />
          <Route path="/onboarding/uniform" element={<Uniform />} />
          <Route path="/onboarding/bank" element={<BankDetails />} />
          <Route path="/onboarding/nightwork" element={<NightWorker />} />
          <Route
            path="/onboarding/optout-agreement"
            element={<OptoutAgreement />}
          />

          <Route path="/contract-agreement" element={<ContractAgreement />} />
        </>
      )}

      {/* Staff Routes */}
      {userLevel >= USER_LEVELS.STAFF && (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/compliance" element={<Complaince />} />
          <Route path="/dashboard/timesheet" element={<Timesheet />} />
          <Route path="/dashboard/profile/edit" element={<Profile />} />
        </>
      )}
    </Routes>
  </AuthProvider>
);
