import {
  Box,
  Flex,
  Heading,
  Image,
  SkeletonText,
  Stack,
  Text,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

interface HeroProps {
  videURL: string;
  minH: String;
  heroTitle: string;
  icon: ReactNode;
  heroDescription: string;
  heroLead: string;
  heroActionButton: String;
  avatar: String;
  others: String;
  mt: String;
  imageURL: String;
  breadcrumb: string;
}
export const Hero = (props: HeroProps) => {
  const {
    imageURL,
    minH,
    videURL,
    heroTitle,
    heroDescription,
    heroLead,
    heroActionButton,
    breadcrumb,
    ...rest
  } = props;

  return (
    <Box className="relative border-none w-full">
      {/* Video Background */}
      {videURL == null ? (
        <>
          <Image
            // fallback={

            //   <SkeletonText size="full" skeletonHeight="30" noOfLines={1} />
            // }
            src={imageURL}
            style={{ width: "100%", height: "60dvh", objectFit: "cover" }}
          />
        </>
      ) : (
        <video
          autoPlay
          loop
          muted
          // height={"100%"}
          // minHeight={"100vh"}

          playsInline
          style={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover",
            zIndex: -1,
            // position: 'absolute',
            right: 0,
            bottom: 0,
          }}
        >
          <source src={videURL} type="video/mp4" />
          {/* Add additional video formats (WebM, Ogg) for browser compatibility */}
        </video>
      )}

      <div
        style={{
          background: "rgba(30, 58, 138,0.7)",
        }}
        className="flex  flex-col  w-full   mx-auto bg-blue-900 absolute top-0 bottom-0 right-0 left-0 items-center justify-center z-[1]  text-white backdrop-blur-md"
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="flex  flex-col  w-full   mx-auto  items-center justify-center z-[1] gap-3 lg:gap-3 text-white text-center px-5  md:w-[86%] text-balance"
        >
          <h1
            className="text-6xl font-bold tracking-tight text-white md:text-6xl  lg:text-7xl xl:text-8xl 2xl:text-9xl"
            color={"blue.50"}
            // textShadow="2px 2px 10px #848688"
          >
            {heroTitle}
          </h1>
          <p
            className={`text-balance text-center tracking-tight text-xl md:text-2xl  w-full mx-auto ${
              !heroDescription && "hidden"
            }`}
          >
            {heroDescription}
          </p>

          {breadcrumb}

          {/* <p className="text-center italic text-[20px] text-base">
            {heroLead}
          </p> */}
          {heroActionButton}
        </motion.div>
      </div>
    </Box>
  );
};
